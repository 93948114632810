<div class="pay-form">
  <h1>Оплата подписки</h1>

  <form ngNoForm method="post" [formGroup]="payForm"
        action="https://securepay.tinkoff.ru/v2/Init"
        (ngSubmit)="onSubmit(payForm.value)" #pay>
    <input name="TerminalKey" formControlName="TerminalKey" type="hidden">
    <input name="Amount" formControlName="Amount" type="hidden">
    <input name="OrderId" formControlName="OrderId" type="hidden">
    <input name="OrderNum" formControlName="OrderNum" type="hidden">
    <input name="Description" formControlName="Description" type="hidden">
    <input name="Token" formControlName="Token" type="hidden">
    <input name="DATA" formControlName="DATA" type="hidden">

    <div class="important">
      Благодарю всех, кто поддерживает проект, несмотря на периодические неудобства - это очень ценно.<br>
      Стоимость и тарифы будут периодически корректироваться для поддержки проекта. О проекте подробнее <a
        target="_blank" href="https://add.betatrader.ru/openinterest">здесь</a>.<br><br>
      Стараемся решать вопросы по возможности
      быстро. Если что-то не так вы всегда можете обратиться в техподдержку в <a target="_blank"
        href="https://t.me/betatrader_ru">Телеграм</a> или на
      <a target="_blank" href="mailto:info@betatrader.ru">электронную почту</a>.<br><br>
      С уважением, Дмитрий Полищук
    </div>

    <mat-label>Выберите один из вариантов подписки и способ оплаты:</mat-label>
    <mat-radio-group class="radio-group" name="tariff" formControlName="tariff">
      <mat-radio-button class="radio-button" *ngFor="let tariff of tariffs" [value]="tariff.id"
                        (change)="change_tf($event)">
        <label>{{tariff.name}}</label> - <span>{{tariff.cost}} ₽</span><span class="tariff-full-cost"
                                                                             *ngIf="tariff.full_cost != 0"><s>{{tariff.full_cost}}
        ₽</s></span>
      </mat-radio-button>
    </mat-radio-group>

    <div class="form-describe">Нажимая на кнопку, вы соглашаетесь с условиями обработки персональных данных и <a
      href="https://add.betatrader.ru/privacy">политикой конфиденциальности</a></div>

    <mat-dialog-actions>

      <button mat-raised-button color="primary" [disabled]="payForm.invalid" type="submit">{{'Pay'|translate}}</button>
    </mat-dialog-actions>
    <div class="form-describe">
      <p>Продление подписки происходит автоматически после оплаты, но всё же рекомендуем проверять дату окончания
        подписки в <a href="#/lk">личном кабинете</a></p>
      Если возникли какие-то проблемы обращайтесь в Телеграм <a href="https://t.me/betatrader_ru">&#64;betatrader_ru</a>
      или на электронную почту <a href="mailto:info@betatrader.ru">info&#64;betatrader.ru</a><br>
    </div>
  </form>
</div>
